import '../scss/style.scss';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Sticky from 'sticky-js';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import AOS from 'aos';
import { Loader } from '@googlemaps/js-api-loader';

//Import 985x753.png image route
import dummyImage985x753 from '../assets/images/985x753.png';

document.addEventListener('DOMContentLoaded', function () {
    const links_anchors = document.querySelectorAll('.scroll-link');

    links_anchors.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault();

            const targetId = this.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const scrollTop = parseInt(this.getAttribute('data-scroll-top')) || 0;

                window.scrollTo({
                    top: targetElement.offsetTop - scrollTop,
                    behavior: 'smooth'
                });
            }
        });
    });

    const loader = new Loader({
        apiKey: googleApiKey,
        version: 'weekly',
    });

    let map;
    var defaultLocation = {lat: 6.235, lng: -75.5752};

    function initMap() {
        map = new google.maps.Map(document.getElementById('map'), {
            center: defaultLocation,
            zoom: 12,
            styles: [
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                        { "color": "#AEBCD4" }
                    ]
                }
            ]
        });


        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position) {
                var userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                map.setCenter(userLocation);
            }, function() {
                handleLocationError(true, map.getCenter());
            });
        } else {
            handleLocationError(false, map.getCenter());
        }

        const markerIcon = {
            path: 'M6 0C2.7 0 0 2.7 0 6s6 10 6 10 6-7.2 6-10S9.3 0 6 0zM6 8c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2S7.1 8 6 8z',
            fillColor: '#3E3A39',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 2
        };

        let mapItems = document.querySelectorAll('.map-item');
        let markers = [];
        mapItems.forEach(mapItem => {
            let title = mapItem.getAttribute('data-title');
            let city = mapItem.getAttribute('data-city');
            let type = mapItem.getAttribute('data-type');

            let lat = mapItem.getAttribute('data-lat');
            let lng = mapItem.getAttribute('data-lng');

            let information = mapItem.getAttribute('data-information');

            if (!lat || !lng) return;

            lat = parseFloat(lat);
            lng = parseFloat(lng);

            let marker = new google.maps.Marker({
                itemId: mapItem.getAttribute('id'),
                position: { lat: lat, lng: lng },
                map: map,
                title: title,
                icon: markerIcon,
                city: city,
                type: type,
            });

            marker.addListener('click', function () {
                const info = { title: title, information:information };

                showInfoWindow(info, this);
            });

            markers.push(marker);
        });

        const cityMap = document.getElementById('city-map');
        const filterButtons = document.querySelectorAll('.filter-buttons');
        filterButtons.forEach(filterButton => {
            filterButton.addEventListener('click', function (e) {
                e.preventDefault();
                cityMap.dispatchEvent(new Event('change'));

                const type = this.getAttribute('data-type');
                setCurrentFilterButtons(this);

                markers.forEach(marker => {
                    const mapItem = document.getElementById(marker.itemId);
                    if (marker.getMap() === null) return;

                    if (marker.type === type) {
                        mapItem.style.display = 'block';

                        if (!marker.getMap()) marker.setMap(map);
                    } else {
                        mapItem.style.display = 'none';
                        marker.setMap(null);
                    }
                });

                centerMap();
            });
        });

        cityMap.addEventListener('change', function () {
            const cityElement = this;

            markers.forEach(marker => {
                const mapItem = document.getElementById(marker.itemId);

                marker.setMap(null);
                mapItem.style.display = 'none';

                setCurrentFilterButtons(null);

                if (this.value === marker.city) {
                    mapItem.style.display = 'block';

                    if (!marker.getMap()) marker.setMap(map);
                } else if (this.value === '') {
                    mapItem.style.display = 'block';

                    if (!marker.getMap()) marker.setMap(map);
                }

            });

            // Hide filter buttons if there are no markers
            filterButtons.forEach(button => {
                if (cityElement.value == '') {
                  button.style.display = 'none';
                  button.classList.remove('active');

                  return;
                }

                let hasMarkers = markers.some(marker => marker.type === button.getAttribute('data-type') && marker.getMap());
                if (!hasMarkers) {
                    button.style.display = 'none';
                    button.classList.remove('active');
                } else {
                    button.style.display = 'flex';
                    button.classList.add('active');
                }
            });

            centerMap();
        });

        function centerMap() {
            let bounds = new google.maps.LatLngBounds();

            markers.forEach(marker => {
                if (marker.getMap() === null) return;
                bounds.extend(marker.getPosition());
            });

            map.fitBounds(bounds);
        }
    }

    const infoWindows = [];
    function showInfoWindow(info, marker) {
        let infoWindow;

        if (!infoWindows[marker.itemId]) {
            const contentString = `<div>
                <h2 class="text-[#3E3A39] text-[1.8rem] font-bold mb-[1rem] w-[250px]">${info.title}</h2>
                <p class="text-[#3E3A39] text-[1.3rem] mb-[.3rem]">${info.information}</p>
            </div>`;

            infoWindow = new google.maps.InfoWindow({
                content: contentString
            });

            google.maps.event.addListener(infoWindow, 'closeclick', function () {
                /*const allItems = document.querySelectorAll('.map-item');
                allItems.forEach(item => item.style.display = 'block');*/

                const cityMap = document.getElementById('city-map');
                cityMap.dispatchEvent(new Event('change'));
            });

            infoWindows[marker.itemId] = infoWindow;
        } else {
            infoWindow = infoWindows[marker.itemId];
        }

        for (let key in infoWindows) {
            infoWindows[key].close();
        }

        showInfoItem(marker);
        infoWindow.open(map, marker);
    }

    // Show the info item in the list above the map
    function showInfoItem(marker) {
        const allItems = document.querySelectorAll('.map-item');
        allItems.forEach(item => item.style.display = 'none');

        const currentMapItem = document.getElementById(marker.itemId);
        currentMapItem.style.display = 'block';
    }

    if (document.getElementById('map')) loader.load().then(initMap);

    const buttons = document.querySelectorAll('.btn-model');
    const modeloInput = document.getElementById('modeloInput');

    buttons.forEach(button => {
        button.addEventListener('click', function (e) {

            let href = this.getAttribute('href');

            if (href.startsWith('#')) {
                event.preventDefault();

                const dataModel = this.getAttribute('data-model');
                modeloInput.value = dataModel;
                modeloInput.focus();

                console.log(modeloInput.value);

                const targetId = href.substring(1);
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                }
            } else {
                window.location.href = href;
            }
        });
    });

    AOS.init();

    Fancybox.bind('[data-fancybox]');

    let sticky;
    if (window.innerWidth > 680) {
        sticky = new Sticky('.col-fixed');
    }

    window.addEventListener("scroll", function() {
        const header = document.querySelector(".header-2");
        if (window.pageYOffset > 200) {
            header.classList.add("scroll-header");
        } else {
            header.classList.remove("scroll-header");
        }
    });

    const swiper = new Swiper('.video-slider', {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: function () {
                var activeSlide = this.slides[this.activeIndex];
                var videoElement = activeSlide.querySelector('video');

                if (videoElement) {
                    videoElement.addEventListener('ended', function () {
                        if (swiper.isEnd) {
                            swiper.autoplay.stop();
                        } else {
                            swiper.slideNext();
                        }
                    });
                } else {
                    this.autoplay.start();
                }
            },
            slideChangeTransitionEnd: function () {
                var activeSlide = this.slides[this.activeIndex];
                var videoElement = activeSlide.querySelector('video');

                if (videoElement) {
                    videoElement.currentTime = 0;
                    videoElement.play();
                    videoElement.addEventListener('ended', function () {
                        if (swiper.isEnd) {
                            swiper.autoplay.stop();
                        } else {
                            swiper.slideNext();
                        }
                    });

                    videoElement.addEventListener('timeupdate', function () {
                        if (videoElement.currentTime >= videoElement.duration) {
                            swiper.slideNext();
                        }
                    });
                } else {
                    if (swiper.isEnd) {
                        swiper.autoplay.stop();
                    } else {
                        this.autoplay.start();
                    }
                }
            },
        },
    });

    const newssl = document.querySelector('.news-slider');
    if (newssl) {
        const news_swiper = new Swiper('.news-slider', {
            modules: [Navigation, Pagination],
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            }
        });
    }

    const tabs = document.querySelectorAll('.tab-tablet');
    const contents = document.querySelectorAll('.content-tablet');

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', function () {
            tabs.forEach(t => t.classList.remove('active'));
            tab.classList.add('active');

            contents.forEach(content => content.classList.add('hidden'));
            contents[index].classList.remove('hidden');
            contents[index].classList.add('block');
        });
    });

    var mySwiper = new Swiper('.swiper-tabs', {
        allowTouchMove: false,
        breakpoints: {
            0: {
                autoHeight: true,
            },
            750: {
                autoHeight: false,
            }
        }
    });

    document.querySelectorAll('.swiper-nav li').forEach(function (el, index) {
        el.addEventListener('click', function () {
            document.querySelectorAll('.swiper-nav li').forEach(function (navEl) {
                navEl.classList.remove('active');
            });
            this.classList.add('active');
            mySwiper.slideTo(index);
        });
    });

    var colorElements = document.querySelectorAll('.color');

    colorElements.forEach(function (colorElement) {
        colorElement.addEventListener('click', function () {
            var colorCircle = this.getAttribute('data-color-circle');
            var nameColorElements = document.querySelectorAll('.name-color');

            var carColorImage = document.querySelector('.car-color img');

            carColorImage.src = this.getAttribute('data-url');

            colorElements.forEach(function (el) {
                el.classList.remove('active-color');
            });

            this.classList.add('active-color');

            nameColorElements.forEach(function (nameColor) {
                var nameColorData = nameColor.getAttribute('data-color');

                if (colorCircle === nameColorData) {
                    nameColor.classList.add('show-name-color');
                } else {
                    nameColor.classList.remove('show-name-color');
                }
            });
        });
    });

    if (document.querySelector('.open-form-modal')) {
        const open_modal_fixed = document.querySelector('.open-form-modal');
        const close_modal_fixed = document.querySelector('.close-modal-form');
        const modal_fixed = document.querySelector('.modal-form-fixed');

        open_modal_fixed.addEventListener('click', (e) => {
            e.preventDefault();

            modal_fixed.classList.add('flex');
            modal_fixed.classList.remove('hidden');
        });

        close_modal_fixed.addEventListener('click', () => {
            modal_fixed.classList.remove('flex');
            modal_fixed.classList.add('hidden');
        });
    }

    var boxElements = document.querySelectorAll('.box-g');
    var isAnimating = false;

    boxElements.forEach(function (boxElement) {
        boxElement.addEventListener('click', function () {
            if (!isAnimating) {
                isAnimating = true;
                var dataIndex = this.getAttribute('data-index');

                var parentGalleryElements = document.querySelectorAll('.gallery-type1 .parent-gallery');

                parentGalleryElements.forEach(function (parentGalleryElement) {
                    var parentGalleryDataIndex = parentGalleryElement.getAttribute('data-index');

                    if (dataIndex === parentGalleryDataIndex) {
                        parentGalleryElement.classList.add('show-modal-image');
                    } else {
                        parentGalleryElement.classList.remove('show-modal-image');
                    }
                });

                var galleryType1Element = document.querySelector('.gallery-type1');
                galleryType1Element.classList.remove('invisible');

                parentGalleryElements[0].addEventListener('transitionend', function () {
                    isAnimating = false;
                });
            }
        });
    });

    var closeType1Elements = document.querySelectorAll('.close-type1');

    closeType1Elements.forEach(function (closeType1Element) {
        closeType1Element.addEventListener('click', function () {
            var parentGalleryElements = document.querySelectorAll('.parent-gallery');
            parentGalleryElements.forEach(function (parentGalleryElement) {
                parentGalleryElement.classList.remove('show-modal-image');
            });
            var galleryType1Element = document.querySelector('.gallery-type1');
            galleryType1Element.classList.add('invisible');
        });
    });

    var nextArrowElements = document.querySelectorAll('.next-arrow-m');

    nextArrowElements.forEach(function (nextArrowElement) {
        nextArrowElement.addEventListener('click', function () {
            var currentVisible = document.querySelector('.parent-gallery.show-modal-image');
            var nextElement = currentVisible.nextElementSibling;

            if (!nextElement) {
                nextElement = document.querySelector('.parent-gallery:first-child');
            }

            currentVisible.classList.remove('show-modal-image');
            setTimeout(function() {
                nextElement.classList.add('show-modal-image');
            }, 800);
        });
    });

    const swiper2 = new Swiper('.gallery-slider', {
        modules: [Navigation, Pagination],
        loop: false,
        spaceBetween: 20,
        slidesPerView: 4,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            750: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            }
        }
    });

    var boxElements = document.querySelectorAll('.box-g-type2');
    var isAnimating = false;

    boxElements.forEach(function (boxElement) {
        boxElement.addEventListener('click', function () {
            if (!isAnimating) {
                isAnimating = true;
                var dataIndex = this.getAttribute('data-index');

                var parentGalleryElements = document.querySelectorAll('.gallery-type2 .parent-gallery2');

                parentGalleryElements.forEach(function (parentGalleryElement) {
                    var parentGalleryDataIndex = parentGalleryElement.getAttribute('data-index');

                    if (dataIndex === parentGalleryDataIndex) {
                        parentGalleryElement.classList.add('show-modal-image');
                    } else {
                        parentGalleryElement.classList.remove('show-modal-image');
                    }
                });

                var galleryType2Element = document.querySelector('.gallery-type2');
                galleryType2Element.classList.remove('invisible');

                parentGalleryElements[0].addEventListener('transitionend', function () {
                    isAnimating = false;
                });
            }
        });
    });

    var closeType2Elements = document.querySelectorAll('.close-type2');

    closeType2Elements.forEach(function (closeType2Element) {
        closeType2Element.addEventListener('click', function () {
            var parentGalleryElements = document.querySelectorAll('.parent-gallery2');
            parentGalleryElements.forEach(function (parentGalleryElement) {
                parentGalleryElement.classList.remove('show-modal-image');
            });
            var galleryType2Element = document.querySelector('.gallery-type2');
            galleryType2Element.classList.add('invisible');
        });
    });

    var nextArrowElements = document.querySelectorAll('.next-arrow-m2');

    nextArrowElements.forEach(function (nextArrowElement) {
        nextArrowElement.addEventListener('click', function () {
            var currentVisible = document.querySelector('.parent-gallery2.show-modal-image');
            var nextElement = currentVisible.nextElementSibling;

            if (!nextElement) {
                nextElement = document.querySelector('.parent-gallery2:first-child');
            }

            currentVisible.classList.remove('show-modal-image');
            setTimeout(function () {
                nextElement.classList.add('show-modal-image');
            }, 800);
        });
    });

    document.querySelectorAll('.big-image').forEach(function (sliderElement) {
        let swiper1 = new Swiper(sliderElement, {
            modules: [Navigation, Pagination],
            loop: false,
            spaceBetween: 10,
            allowTouchMove: false,
        });

        let slider2Element = sliderElement.parentElement.querySelector('.big-text-slider');
        let containerSlider = sliderElement.parentElement.querySelector('.container-slider');
        let swiper3 = new Swiper(slider2Element, {
            modules: [Navigation, Pagination, Thumbs],
            loop: false,
            spaceBetween: 10,
            navigation: {
                prevEl: containerSlider.querySelector('.swiper-button-prev2'),
                nextEl: containerSlider.querySelector('.swiper-button-next2'),
            },
            thumbs: {
                swiper: swiper1,
            },
        });
    });

    var swiper3 = new Swiper(".gallery-scroll", {
        modules: [Navigation, Pagination],
        slidesPerView: "auto",
        spaceBetween: 5,
        navigation: {
            nextEl: ".swiper-button-next3",
            prevEl: ".swiper-button-prev3",
        },
    });

    var boxElements3 = document.querySelectorAll('.box-g-type3');
    var isAnimating3 = false;

    boxElements3.forEach(function (boxElement3) {
        boxElement3.addEventListener('click', function () {
            if (!isAnimating3) {
                isAnimating3 = true;
                var dataIndex3 = this.getAttribute('data-index');

                var parentGalleryElements3 = document.querySelectorAll('.gallery-type3 .parent-gallery3');

                parentGalleryElements3.forEach(function (parentGalleryElement3) {
                    var parentGalleryDataIndex3 = parentGalleryElement3.getAttribute('data-index');

                    if (dataIndex3 === parentGalleryDataIndex3) {
                        parentGalleryElement3.classList.add('show-modal-image');
                    } else {
                        parentGalleryElement3.classList.remove('show-modal-image');
                    }
                });

                var galleryType3Element = document.querySelector('.gallery-type3');
                galleryType3Element.classList.remove('invisible');

                parentGalleryElements3[0].addEventListener('transitionend', function () {
                    isAnimating3 = false;
                });
            }
        });
    });

    var closeType3Elements = document.querySelectorAll('.close-type3');

    closeType3Elements.forEach(function (closeType3Element) {
        closeType3Element.addEventListener('click', function () {
            var parentGalleryElements3 = document.querySelectorAll('.parent-gallery3');
            parentGalleryElements3.forEach(function (parentGalleryElement3) {
                parentGalleryElement3.classList.remove('show-modal-image');
            });
            var galleryType3Element = document.querySelector('.gallery-type3');
            galleryType3Element.classList.add('invisible');
        });
    });

    var nextArrowElements3 = document.querySelectorAll('.next-arrow-m3');

    nextArrowElements3.forEach(function (nextArrowElement3) {
        nextArrowElement3.addEventListener('click', function () {
            var currentVisible3 = document.querySelector('.parent-gallery3.show-modal-image');
            var nextElement3 = currentVisible3.nextElementSibling;

            if (!nextElement3) {
                nextElement3 = document.querySelector('.parent-gallery3:first-child');
            }

            currentVisible3.classList.remove('show-modal-image');
            setTimeout(function () {
                nextElement3.classList.add('show-modal-image');
            }, 800);
        });
    });

    const videos = document.querySelectorAll(".vid-columns");
    videos.forEach((video) => {
        video.addEventListener("click", function () {
            if (video.paused) {
                video.play();

                videos.forEach((otherVideo) => {
                    if (otherVideo !== video && !otherVideo.paused) {
                        otherVideo.pause();
                    }
                });
            } else {
                video.pause();
            }
        });
    });

    const videoPlayButtons = document.querySelectorAll('.video-play');
    videoPlayButtons.forEach(videoPlayButton => {
        videoPlayButton.addEventListener('click', () => {
            videoPlayButtons.forEach(button => {
                const video = button.querySelector('video');
                const playIcon = button.querySelector('.fa-play');
                if (video !== null && playIcon !== null) {
                    video.pause();
                    playIcon.style.display = 'block';
                }
            });

            const video = videoPlayButton.querySelector('video');
            const playIcon = videoPlayButton.querySelector('.fa-play');

            if (video.paused) {
                video.play();
                playIcon.style.display = 'none';
            } else {
                video.pause();
                playIcon.style.display = 'block';
            }
        });
    });

    function toggleFilledClass(field) {
        const label = field.closest("label");

        if (!label) return;

        if (field.tagName === 'SELECT') {
            label.classList.toggle("filled", field.selectedIndex !== 0);
        } else {
            label.classList.toggle("filled", field.value.trim() !== '');
        }
    }
    const forms = document.querySelectorAll('.contact-form');
    forms.forEach((form) => {
        const formFields = form.querySelectorAll("input, textarea, select");

        formFields.forEach((field) => {
            toggleFilledClass(field);

            field.addEventListener("input", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("blur", () => {
                toggleFilledClass(field);
            });

            field.addEventListener("focus", () => {
                toggleFilledClass(field);
            });
        });
    });

    function toggleFilledClass2(field2) {
        const label2 = field2.closest(".gfield");
        if (!label2) return;

        if (field2.tagName === 'SELECT') {
            label2.classList.toggle("filled", field2.selectedIndex !== 0);
        } else {
            label2.classList.toggle("filled", field2.value.trim() !== '');
        }
    }
    function toggleFilledClass2(field2) {
        const label2 = field2.closest(".gfield");
        if (label2) {
            if (field2.value.trim() !== "") {
                label2.classList.add("filled");
            } else {
                label2.classList.remove("filled");
            }
        }
    }

    if(document.querySelector('.gform_wrapper')) {
        function attachFormListeners() {
            const forms2 = document.querySelectorAll('.gform_wrapper form');
            forms2.forEach((form2) => {
                const formFields2 = form2.querySelectorAll("input, textarea, select");
        
                formFields2.forEach((field2) => {
                    toggleFilledClass2(field2);
        
                    field2.addEventListener("input", () => {
                        toggleFilledClass2(field2);
                    });
        
                    field2.addEventListener("blur", () => {
                        toggleFilledClass2(field2);
                    });
        
                    field2.addEventListener("focus", () => {
                        console.log('focus');
                        const label2 = field2.closest(".gfield");
                        if (label2 !== null) {
                            label2.classList.add("filled");
                            console.log('existe !');
                        } else {
                            console.log('no existe !');
                        }
                    });
                });
            });
        }
        
        attachFormListeners();
    
        const observer = new MutationObserver(() => {
            attachFormListeners();
        });

        observer.observe(document.querySelector('.gform_wrapper'), {
            childList: true,
            subtree: true,
        });
    }

    const newsletterForms = document.querySelectorAll('.newsletter-form');
    let isAjaxRunning = false;

    newsletterForms.forEach((form) => {
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            if (isAjaxRunning) return;

            isAjaxRunning = true;

            const formData = new FormData(form);

            fetch(form.getAttribute('action'), {
                method: form.getAttribute('method'),
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    const message = form.querySelector('.message');
                    if (data.success) {
                        message.classList.remove('bg-error');
                        message.classList.add('bg-success');
                    } else {
                        message.classList.remove('bg-success');
                        message.classList.add('bg-error');
                    }

                    message.innerHTML = data.message;
                    message.style.display = 'block';

                    isAjaxRunning = false;
                })
                .catch((error) => {
                    isAjaxRunning = false;
                    console.error('Error:', error);
                });
        });
    });

    // Formulario de cotización
    const quoteForm = document.getElementById('quote-form');
    if (quoteForm) {
        quoteForm.addEventListener('submit', (e) => {
            e.preventDefault();
            //Remoove classes to .message
            const message = quoteForm.querySelector('.message');

            message.classList.remove('bg-error');
            message.classList.remove('bg-success');

            if (isAjaxRunning) return;

            isAjaxRunning = true;

            const formData = new FormData(quoteForm);

            fetch(quoteForm.getAttribute('action'), {
                method: quoteForm.getAttribute('method'),
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        quoteForm.reset();
                        location.href = data.redirect_to;
                    } else {
                        message.classList.remove('bg-success', 'bg-success');
                        message.classList.add('bg-error','bg-error');
                    }

                    message.innerHTML = data.message;
                    message.style.display = 'block';

                    isAjaxRunning = false;
                })
                .catch((error) => {
                    isAjaxRunning = false;
                    console.error('Error:', error);
                });
        });
    }

    const departmentSelect = document.getElementById('departments');
    const citySelect = document.getElementById('cities');

    if (departmentSelect && citySelect) {
        departmentSelect.addEventListener('change', () => {
            const { cities } = departmentSelect.options[departmentSelect.selectedIndex].dataset;
            const parsedCities = JSON.parse(cities);

            citySelect.innerHTML = '';
            citySelect.appendChild(new Option('', ''));

            parsedCities.forEach(city => {
                const option = new Option(city.texto, city.texto);
                citySelect.appendChild(option);
            });
        });
    }

    const modelSelect = document.getElementById('model');
    if (modelSelect) {
        const modelInfo = document.getElementById('model-info');
        const tempDiv = document.createElement('div');
        let modelTitleElement;
        const urlParams = new URLSearchParams(window.location.search);
        const modelParam = urlParams.get('model');
        if(modelParam == null) {
            tempDiv.innerHTML = `<h2 class="relative py-[.5rem] pl-[3rem] text-blue7e text-[3.2rem] before:absolute before:top-[-3px] before:w-[.4rem] before:h-full before:bg-blue8e
                                before:left-0 leading-[.8] mt-[2.4rem] font-HY" id="model-title">
                                    <span class="font-bold text-[3.5rem] text-black38" id="model-line-1"></span>
                                    <span class="text-[3.4rem] text-black38" id="model-line-2"></span>
                                    <span id="model-line-3"></span>
                                </h2>`;
            modelTitleElement = tempDiv.firstElementChild;
        }
        modelSelect.addEventListener('change', () => {
            if(modelParam == null) {
                const modelTitleContainer = document.getElementById('model-title-container');
                modelTitleContainer.insertAdjacentElement('afterbegin', modelTitleElement);
            }
            if (!modelSelect.value) {
                modelInfo.style.visibility = 'hidden';
            } else {
                const { post } = modelSelect.options[modelSelect.selectedIndex].dataset;
                const parsedPost = JSON.parse(post);

                if (setModelData(parsedPost)) modelInfo.style.visibility = 'visible';
                else modelInfo.style.visibility = 'hidden';
            }
            if (window.innerWidth > 680) {
                sticky.update();
            }
        });

        const { post } = modelSelect.options[modelSelect.selectedIndex].dataset;
        if (post) {
            const parsedPost = JSON.parse(post);

            if (setModelData(parsedPost)) modelInfo.style.visibility = 'visible';
            else modelInfo.style.visibility = 'hidden';
        }

        if (window.innerWidth > 680) {
            const modelImage = document.getElementById('model-image');
            modelImage.querySelector('img').src = dummyImage985x753;

            sticky.update();
        }
    }

    function isScreenBelow780px() {
        return window.innerWidth < 780;
    }

    const links = document.querySelectorAll('.a-mega-menu-parent');

    if (isScreenBelow780px()) {
        links.forEach(link => {
            link.addEventListener('click', function (e) {
                e.preventDefault();
                const siblingMega = this.nextElementSibling;
                if (siblingMega && siblingMega.classList.contains('mega') && siblingMega.classList.contains('show')) {
                    siblingMega.classList.remove('show');
                } else if (siblingMega && siblingMega.classList.contains('mega')) {
                    e.preventDefault();
                    const allMegas = document.querySelectorAll('.nav-main .mega');
                allMegas.forEach(mega => {
                    mega.classList.remove('show');
                });
                    siblingMega.classList.add('show');
                }
            });
        });
    }

    const openMobileLinks = document.querySelectorAll('.open-mobile');
    const headerMain = document.querySelectorAll('.header-g');
    const body = document.querySelector('body');
    const menuIcon = document.querySelectorAll('.lni-menu');

    let isMenuOpen = false;

    function toggleNavigationMobile(event) {
        const parentHeader = event.currentTarget.closest('.header-g');
        const navigationM = parentHeader.querySelector('.navigation-m');

        if (isMenuOpen) {
            navigationM.classList.remove('md:flex', 'nav-mobile');
            navigationM.classList.add('md:hidden', 'nav-mobile');
            parentHeader.classList.remove('header-nav-mobile');
            body.classList.remove('no-scroll');
            menuIcon.forEach(item3 => {
                item3.classList.remove('lni-close');
                item3.classList.add('lni-menu');
            });
        } else {
            navigationM.classList.remove('md:hidden', 'nav-mobile');
            navigationM.classList.add('md:flex', 'nav-mobile');
            parentHeader.classList.add('header-nav-mobile');
            body.classList.add('no-scroll');
            menuIcon.forEach(item3 => {
                item3.classList.remove('lni-menu');
                item3.classList.add('lni-close');
            });
        }
        isMenuOpen = !isMenuOpen;
    }

    openMobileLinks.forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
            toggleNavigationMobile(event);
        });
    });

    const linksA = document.querySelectorAll('.nav-car a[href*="#"]');

    linksA.forEach(link => {
        link.addEventListener('click', e => {
            e.preventDefault();
            const parentHeader = e.currentTarget.closest('.header-g');
            const navigationM = parentHeader.querySelector('.navigation-m');
            const targetId = link.getAttribute('href').substring(1);
            const targetSection = document.getElementById(targetId);

            if (targetSection) {
                const headerHeight = document.querySelector('.header-g').offsetHeight;
                const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY - headerHeight;
                navigationM.classList.remove('md:flex');
                navigationM.classList.add('md:hidden');
                parentHeader.classList.remove('header-nav-mobile');
                body.classList.remove('no-scroll');
                menuIcon.forEach(item3 => {
                    item3.classList.remove('lni-close');
                    item3.classList.add('lni-menu');
                });
                isMenuOpen = !isMenuOpen;
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        });
    });

    function toggleMegaMenuItems(eventType) {
        const menuItems = document.querySelectorAll('.mega-menu-link-item');
        menuItems.forEach(itemLink => {
            itemLink.addEventListener(eventType, function () {
                const megaMenuItems = document.querySelectorAll('.mega-menu-child-item');
                const currentMegaMenuItems = document.querySelectorAll(`.${itemLink.dataset.megaMenuItems}`);

                megaMenuItems.forEach(item => item.classList.add('hidden'));
                currentMegaMenuItems.forEach(item => item.classList.remove('hidden'));
            });
        });
    }

    function setupParentMenuClick(eventType) {
        const megaMenuParents = document.querySelectorAll('.mega-menu-parent');
        megaMenuParents.forEach(parent => {
            const parentLink = parent.querySelector('a');
            parentLink.addEventListener(eventType, function () {
                const firstChild = parent.querySelector('.mega-menu-link-item');
                firstChild.dispatchEvent(new Event(eventType));
            });
        });
    }

    const eventType = isScreenBelow780px() ? 'click' : 'mouseover';
    toggleMegaMenuItems(eventType);
    setupParentMenuClick(eventType);

    if (document.querySelector('.vid-columns')) {
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting && !entry.target.played.length) {
                    entry.target.play();
                    observer.unobserve(entry.target);
                }
            });
        }

        let videos = document.querySelectorAll('.vid-columns');

        videos.forEach(function (video) {
            const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
            observer.observe(video);
        });
    }

    // Selecciona todos los formularios con la clase 'appointments-form'
    let appointmentForms = document.querySelectorAll('.appointments-form');

    appointmentForms.forEach(function(form) {
        let isAjaxRunning = false;

        form.addEventListener('submit', function (e) {
            e.preventDefault();

            var formData = new FormData(form);
            let ajaxurl = form.getAttribute('action');

            let successMessageElement = form.querySelector('.js-success-message');
            let errorMessageElement = form.querySelector('.js-error-message');

            successMessageElement.innerHTML = '';
            errorMessageElement.innerHTML = '';

            successMessageElement.style.display = 'none';
            errorMessageElement.style.display = 'none';

            if (isAjaxRunning) return;

            isAjaxRunning = true;

            fetch(ajaxurl, {
                method: 'POST',
                credentials: 'same-origin',
                body: formData
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    form.reset();

                    successMessageElement.innerHTML = data.message;
                    successMessageElement.style.display = 'block';
                } else {
                    var errorMessage = 'Error: ' + (data.message || 'Algo salió mal.');
                    if (data.errors && data.errors.length > 0) {
                        errorMessage += '\n' + data.errors.join('\n');
                        errorMessage = data.errors.join('<br>');

                        errorMessageElement.innerHTML = errorMessage;
                        errorMessageElement.style.display = 'block';
                    }
                }
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
                isAjaxRunning = false;
            });
        });
    });

    // Menú Lateral
    document.querySelectorAll('.open-side-menu').forEach((trigger, index) => {
        const sideMenu = document.querySelectorAll('.side-menu')[index];
        const sideMenuCloseButtons = sideMenu.querySelectorAll('.close-side-menu, .side-menu-overlay');

        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            document.body.classList.add('no-scroll');
            trigger.classList.add('fade-out');
            sideMenu.classList.add('active');
        });

        sideMenuCloseButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                document.body.classList.remove('no-scroll');
                trigger.classList.remove('fade-out');
                sideMenu.classList.remove('active');
            });
        });
    });

    const sideItemHasChildren = document.querySelectorAll('.side-menu a.menu-item-has-children, .all-other-links-for-mobile a.menu-item-has-children');

    sideItemHasChildren.forEach(sideItem => {
        sideItem.addEventListener('click', (e) => {
            e.preventDefault();
            sideItem.classList.toggle('active');
        });
    })

    // Tabs Contacto
    if(document.querySelector('.contact-tabs')) {
        const tabs = document.querySelectorAll('.tab');
        const triggers = document.querySelectorAll('.tab-trigger');

        triggers.forEach(trigger => {
            trigger.addEventListener('click', function(e) {
                e.preventDefault();

                let dataTab = trigger.dataset.tab;

                tabs.forEach(tab => {
                    tab.classList.add('hidden');
                });

                const activeTab = document.querySelector(`#${dataTab}`);
                if (activeTab) {
                    activeTab.classList.remove('hidden');
                    activeTab.classList.add('block');
                }
            });
        });
    }
});

function setCurrentFilterButtons(currentButton) {
    const filterButtons = document.querySelectorAll('.filter-buttons');

    filterButtons.forEach(button => {
        if (button === currentButton) {
            button.style.backgroundColor = '#4B4B4B';
            button.style.color = 'white';

            button.classList.add('active');
        } else {
            button.style.backgroundColor = 'white';
            button.style.color = '#4B4B4B';

            button.classList.remove('active');
        }
    });

}

function setModelData(post) {
    const modelInfo = document.getElementById('model-info');

    if (!post) return false;
    if (!modelInfo) return false;

    const {
        titulo_cotizador_linea_1,
        titulo_cotizador_linea_2,
        titulo_cotizador_linea_3,
        quotation_image,
    } = post;

    const modelTitle1 = document.getElementById('model-line-1');
    const modelTitle2 = document.getElementById('model-line-2');
    const modelTitle3 = document.getElementById('model-line-3');

    modelTitle1.innerHTML = titulo_cotizador_linea_1;
    modelTitle2.innerHTML = titulo_cotizador_linea_2;
    modelTitle3.innerHTML = titulo_cotizador_linea_3;

    const modelImage = document.getElementById('model-image');

    modelImage.querySelector('img').src = quotation_image.url;
    if (window.innerWidth > 680) {
        const sticky = new Sticky('.col-fixed');
        sticky.update();
    }

    return true;
}